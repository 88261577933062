img {
    height: auto;
    width: auto\9; /* ie8 */
}

.projectContainer {
    display: grid; position: relative; 
    grid-template-columns: repeat(2, 40vw); 
    grid-template-rows: repeat(2, auto); 
    /* background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%); */
    margin: 2vw; top: 0; 
    border: 0.2vw solid var(--col6); 
    border-radius: 0.5vw;   
    transition: top ease 0.5s;
}

.infoContainer {  
    margin: 2vw; 
}

.projectHeader {
    font-size: 4vw; 
    color: var(--col5);
    /* -webkit-text-stroke: 0.1vw var(--col5); */
    border-bottom: 0.2vw solid var(--col6); 
    padding: 0.5vw; 
    text-transform: uppercase; 
    font-family: 'Inconsolata', monospace; font-weight: 100; 
}

.projectInfo {
    grid-column-start: 1; 
    grid-row-start: 2; 
    position: relative; 
    font-size: 1.8vh; 
    color: var(--col5);
    padding: 1vw; 
    font-weight: 100; font-family: 'Inconsolata', monospace;
}

.imageHolder {
    border-radius: 0.5vw; 
    margin: 2vw; margin-left: 12vw;  
    width: 40vw; height: 39vh; 
    object-fit: cover; overflow: hidden;
}

.codeBtn {
    align-items: center; justify-content: center; 
    letter-spacing: 2px; font-size: 1vw;
    text-transform: uppercase; 
    height: 4vh; width: 9vw;
    display: inline-block;   
    color: var(--col5); 
    border: 4px solid var(--col6); 
    background: -webkit-linear-gradient(0deg, transparent 50%, var(--col5) 50%); 
    background-size: 100vw 100vw; 
    transition: 0.5s; 
    margin: 1vw; 
    font-weight: 100; font-family: 'Inconsolata', monospace;
}
  
.codeBtn:hover {
    color: var(--col1); 
    cursor: pointer; 
    background-position: 50vw 0; 
}

.projectBtn {
    align-items: center;
    justify-content: center; 
    letter-spacing: 2px; 
    text-transform: uppercase;  
    margin-top: 2vw; 
    height: 4vh; 
    width: 12vw;
    display: inline-block; 
    text-decoration: none; 
    font-size: 1vw;  
    color: var(--col5); 
    border: 4px solid var(--col6); 
    background: -webkit-linear-gradient(0deg, transparent 50%, var(--col5) 50%); 
    background-size: 100vw 100vw; 
    transition: 0.5s; 
    margin: 1vw; 
    font-weight: 100; font-family: 'Inconsolata', monospace;
}
  
.projectBtn:hover {
    color: var(--col1); 
    cursor: pointer; 
    background-position: 50vw 0; 
}
  