
:root {
  /* --col1: #000000;
  --col2: #002f06;
  --col3: #1c6000;
  --col4: #27a102;
  --col5: #1fea00;
  --col6: #01fe87;  */
  --col0: #141313;
  --col1: #000000; 
  --col2: #379683; 
  --col3: #5CDB95; 
  --col4: #8EE4Af; 
  --col5: #EDF5E1;
  --col6: #01fe87;
  --col7: #E7FCDC;
  --col8: #78866B;
  --col9: #4cc35b; 
  --col10: #c7c6c1;
}

.app {
  width: 100%; 
  height: 100%; 
}

* {
  box-sizing: border-box; 
  margin: 0; 
  padding: 0; 
  font-family: 'Lato', sans-serif;
}

body {
  background-color: var(--col1); 
  min-height: 100vh;
}



/* =================================================================
colors:https://color.adobe.com/search?q=dark
#121D26
#394D59
#4D6873
#C1D4D9
#0D0D0D
*/ 
