
  
.heading {
    grid-column-start: 1; grid-column-end: 3;grid-row-start: 1; grid-row-end: 2; 
    position: relative; 
    width: 50rem; 
    font-size: 10rem;font-weight: 800;
    color: var(--col8);
    margin-left: 6rem; 
    z-index: 1; 
} 
  


.typeWrite {
    grid-column-start: 1; grid-column-end: 3;
    grid-row-start: 2; grid-row-end: 3; 
    white-space: nowrap; 
    color: var(--col3);
    margin-left: 6rem; 
    letter-spacing: .15rem;   
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink-caret {
    from, to {border-color: transparent}
    50% {border-color: var(--col3); }
}

.resumeButton {
    display: flex; 
    align-items: center;
    justify-content: center; 
    letter-spacing: 2px; 
    text-transform: uppercase; 
    margin-top: 2vw; 
    height: 10vh; 
    width: 95.5vw;
    text-decoration: none; 
    font-size: 1.5vw;  
    color: white; 
    background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
    border: 4px solid var(--col6); 
    border-radius: 0.5vw;
    margin: 2vw; 
    transition: 0.5s; 
}

.resumeButton:hover {
    box-shadow: rgba(95, 95, 95, 0.25) 0px 54px 55px, rgba(95, 95, 95, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.headerStyle {
    grid-column-start: 1;; grid-row-start: 2; 
    position: relative; margin-left: 2vw; 
    width: 29.5vw; font-size: 7vw; 
    color: var(--col4); -webkit-text-stroke: 0.1vw var(--col4); text-transform: uppercase; 
}

.aboutBtn {
    -webkit-text-stroke: 0.1vw var(--col5); position: relative; 
    width: 29.5vw; height: 7vw; 
    letter-spacing: 2px; font-size: 3vw; text-transform: uppercase; 
    color: var(--col5); margin-top: 3vh; 
    border: 4px solid var(--col3); border-radius: 1.3vw;  
    background: -webkit-linear-gradient(0deg, transparent 50%, var(--col5) 50%);  background-size: 100vw 100vw; 
    transition: 0.5s; 
}
  
.aboutBtn:hover {
    color: var(--col1); 
    -webkit-text-stroke: 0.1vw var(--col1);
    cursor: pointer; 
    background-position: 50vw 0; 
}

*::-webkit-scrollbar {
    width: 0.6vw;
    height: 1vh 
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--col7);
    border-radius: 10px;
    border: 3px solid var(--col1)
  }

.container1 {
    display: grid; 
    grid-template-columns: repeat(4, 1fr);      
    grid-template-rows: repeat(4, 1fr); height: 90vh; 
}

.container2 { 
    display: grid; 
    grid-template-rows: repeat(5, auto); 
    grid-template-columns: repeat(4, auto); 
    margin: 5vw; margin-top: 5vh; 
    padding: 2vw;
    /* border: 0.2vw solid var(--col6);  */
    background-color: rgba(0, 0, 0, 0.3);
    grid-column-gap: 1vw;
}

.aboutHeader {
    background: var(--col1); display: table; 
    position: relative;  
    text-transform: none;
    white-space: nowrap; 
    font-size: 7vh; height: fit-content; 
    font-family: 'Inconsolata', monospace; font-weight: 100; 
} 

.aboutHeader:after {
    border-top: 2px solid var(--col6);
    content: '';
    display: table-cell;
    position: relative;
    top: 5.2vh;
    width:52%;
}

.aboutText {
    background: var(--col1); 
    position: relative; 
    text-transform: none;
    font-size: 25px;  
    grid-row-start: 2; 
    grid-column-start: 1; 
    color: var(--col8);
    width: 35rem; padding: 1vw; 
    transition: 0.5s; 
    font-family: 'Inconsolata', monospace; 
}

.profilePic {
    height: 40rem; width: 100rem; 
    overflow: hidden; position: relative; 
    grid-column-start: 2; 
    grid-row-start: 1; 
    grid-row-end: 4; 
    grid-column-end: 4;
    object-fit: cover; 
    margin-top: 3rem; margin-bottom: 3rem; 
}

.contacts {
    grid-column-start: 1; grid-row-start: 3; 
    background-color: rgba(0, 0, 0, 0);
    display: grid; 
    grid-template-columns: repeat(5, auto); 
    margin: auto; margin-left: 0vw; margin-right: 0; 
    color: var(--col5); 
}

.textbox {
    font-size: 1px; 
    grid-column-start: 3; grid-column-end: 3; grid-row-start: 1; grid-row-end: 1;
    background: var(--col1); 
    position: relative; 
    padding: 10px 30px; 
    border: 2px solid var(--col6);
    border-radius: 25px;  
    display: inline-block; 
    text-decoration: none; 
    letter-spacing: 2px; 
    text-transform: uppercase; 
    transition: 0.5s; 
    margin: 20px; 
}

img { object-fit: cover; }

.hobbies {
    grid-column-start: 1; grid-column-end: 4; grid-row-start: 4; 
    background: var(--col1); 
    position: relative; 
    padding-left: 10px; 
    display: table; 
    text-transform: none; white-space: nowrap; 
    font-size: 4vw; 
    color: var(--col10);
    font-family: 'Inconsolata', monospace; font-weight: 100; 
}

.hobbies:after {
    border-top: 2px solid var(--col6);
    content: '';
    display: table-cell;
    position: relative;
    top: 3vw;
    width:75%;
}

.buttonContainer {
    grid-column-start: 1; grid-row-start: 5;  
    background-color: rgba(0, 0, 0, 0);
    display: grid; height: 50vh; 
    grid-template-rows: repeat(5, auto); 
    color: var(--col5); margin-left: 2vw; 
}

.hobbieContainer {
    grid-column-start: 2; grid-column-end: 4; grid-row-start: 5;  
    background-color: rgba(0, 0, 0, 0);
    display: grid; height: 40vh; 
    grid-template-rows: repeat(5, auto); 
    transition: 0.5s; 
    align-items: right; 
}

.hobbieTitle {
    background: var(--col1); 
    content: attr(data-text); 
    text-transform: none;
    font-size: 5vh;  
    grid-row-start: 1; 
    color: var(--col9); 
    height: 8.5vh; width: auto; margin-bottom: 5vh; 
    font-family: 'Inconsolata', monospace; 
}

.hobbieDiv {
    background: var(--col1); 
    content: attr(data-text); 
    text-transform: none;
    font-size: 15.3px;  
    grid-row-start: 3; 
    color: var(--col8);
    height: 36.5vh; width: 50vw;
    overflow: overlay; 
    font-family: 'Inconsolata', monospace; 
}

.hobbieButtons {
    height: 8.5vh; width: 30vw; relative: top; 
    text-decoration: none; 
    font-size: 2vw; 
    text-align: left; margin: auto; padding-left: 15px; 
    color: var(--col8); 
    /* background: -webkit-linear-gradient(0deg, transparent 50%, var(--col5) 50%); background-size: 100vw 100vw;  */
    font-family: 'Inconsolata', monospace; 
}

.hobbieButtons:focus { 
    border-left: solid 3px var(--col6); color: var(--col6); 
    background-color: var(--col0); transition: 0.3s; 
}

.hobbieButtons:hover { opacity: 0.5; transition: 0.3s; }

.icons{
    color: rgb(22 163 74); 
    font-size: 3vw; 
    transition: 0.5s; align-self: bottom;
}

.icons:hover { 
    color: white; 
}
  
span {
    pointer-events: none;
    background: transparent;
    font-family: 'Inconsolata', monospace;
}
  
.title {
    font-size: 15em;
    text-align: center;
    font-family: 'Inconsolata', monospace;
}

h3 {
    color: var(--col6); 
    font-family: 'Inconsolata', monospace;
}

h5 {
    color: var(--col5); 
}

.timelineHeader {
    display: flex; position: relative; 
    padding: 10px 30px; margin: auto; margin-top: 5vh; 
    font-size: 3vh;  
    grid-row-start: 2; grid-column-start: 1; 
    align-items: center; justify-content: center;
    color: var(--col10); border: solid 2px var(--col6); background-color: var(--col0); 
    height: 10vh; width: fit-content; 
    font-family: 'Inconsolata', monospace; font-weight: 100; 
}

.vertical-timeline-element-content {
    font-family: 'Inconsolata', monospace;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
    border: solid 2px var(--col6); 
}

.vertical-timeline-element-location {
    font-family: 'Inconsolata', monospace; 
}

.date {
    color: rgb(201, 251, 255);
}

.vertical-timeline.vertical-timeline-custom-line::before {
    background: var(--col6);
}
.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px var(--col6), inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

#description {
    font-family: 'Inconsolata', monospace;
    color: var(--col8); 
}

@media only screen and (max-width: 1700px) {
    .vertical-timeline-element-date {
      display: block !important;
      float: none !important;
      color: rgb(248, 241, 241);
    }
  }